// src/mouse.js

// Fonction pour changer l'icône de la souris en ajoutant une classe CSS
function changeCursorClass(element, cursorClass) {
  element.classList.remove(
    "cursor-rotate",
    "cursor-zoom-in",
    "cursor-zoom-out",
    "cursor-dolly",
    "cursor-zoom-in-map",
    "cursor-zoom-out-map",
    "cursor-pan",
    "cursor-default"
  );
  if (cursorClass) {
    element.classList.add(cursorClass);
  } else {
    element.classList.add("cursor-default");
  }
}

// Gestionnaire de la souris pour les différents événements
function setupMouseHandlers(controlsElement, controls) {
  let isMiddleMouseDown = false;
  let lastMouseY = 0;
  let wheelTimeout;

  // Écouteur pour le clic gauche (ROTATE)
  controlsElement.addEventListener("mousedown", (event) => {
    if (event.button === controls.mouseButtons.LEFT) {
      changeCursorClass(controlsElement, "cursor-rotate");
    }
  });

  // Écouteur pour la roulette de la souris (DOLLY)
  controlsElement.addEventListener("wheel", (event) => {
    if (event.deltaY < 0) {
      // Roulette vers le haut
      changeCursorClass(controlsElement, "cursor-zoom-in");
    } else {
      // Roulette vers le bas
      changeCursorClass(controlsElement, "cursor-zoom-out");
    }

    // Réinitialiser le délai à chaque utilisation de la roulette
    clearTimeout(wheelTimeout);
    wheelTimeout = setTimeout(() => {
      changeCursorClass(controlsElement, "cursor-default");
    }, 350); // Réinitialiser après 1 seconde d'inactivité
  });

  // // Écouteur pour le clic du milieu (DOLLY)
  // controlsElement.addEventListener("mousedown", (event) => {
  //   if (event.button === controls.mouseButtons.MIDDLE) {
  //     // if (event.deltaY < 0) {
  //     //   // Roulette vers le haut
  //     //   changeCursorClass(controlsElement, "cursor-zoom-in");
  //     // } else {
  //     //   // Roulette vers le bas
  //     //   changeCursorClass(controlsElement, "cursor-zoom-out");
  //     // }
  //     changeCursorClass(controlsElement, "cursor-dolly");
  //   }
  // });

  // Écouteur pour le clic du milieu (DOLLY)
  controlsElement.addEventListener("mousedown", (event) => {
    if (event.button === controls.mouseButtons.MIDDLE) {
      isMiddleMouseDown = true;
      lastMouseY = event.clientY;
      changeCursorClass(controlsElement, "cursor-dolly");
    }
  });

  // Écouteur pour le mouvement de la souris
  controlsElement.addEventListener("mousemove", (event) => {
    if (isMiddleMouseDown) {
      if (event.clientY < lastMouseY) {
        // Mouvement vers le haut
        changeCursorClass(controlsElement, "cursor-zoom-in-map");
      } else if (event.clientY > lastMouseY) {
        // Mouvement vers le bas
        changeCursorClass(controlsElement, "cursor-zoom-out-map");
      }
      lastMouseY = event.clientY;
    }
  });

  // Réinitialiser l'icône de la souris lorsque le bouton de la souris est relâché
  controlsElement.addEventListener("mouseup", (event) => {
    if (event.button === controls.mouseButtons.MIDDLE) {
      isMiddleMouseDown = false;
      changeCursorClass(controlsElement, "cursor-default");
    }
  });

  // Écouteur pour le clic droit (PAN)
  controlsElement.addEventListener("mousedown", (event) => {
    if (event.button === controls.mouseButtons.RIGHT) {
      changeCursorClass(controlsElement, "cursor-pan");
    }
  });

  // Réinitialiser l'icône de la souris lorsque le bouton de la souris est relâché
  controlsElement.addEventListener("mouseup", () => {
    changeCursorClass(controlsElement, "cursor-default");
  });

  // Réinitialiser l'icône de la souris lorsque la souris quitte l'élément
  controlsElement.addEventListener("mouseleave", () => {
    if (isMiddleMouseDown) {
      isMiddleMouseDown = false;
      changeCursorClass(controlsElement, "cursor-default");
    }
  });
}

export { setupMouseHandlers };
